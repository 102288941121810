<template>
  <div
    v-if="(products && products.length) || (items && items.length) || ($slots.products && $slots.products.length)"
    class="product-list-swipeable"
    :class="[mobileMargin ? 'mm--' + mobileMargin : '', desktopMargin ? 'dm--' + desktopMargin : '']"
  >
    <div
      class="list-header"
      :class="[mobilePadding ? 'mp--' + mobilePadding : '', desktopPadding ? 'dp--' + desktopPadding : '', isAfterCheckout ? 'after-checkout' : '']"
    >
      <slot name="headline" />
      <div v-if="!isAfterCheckout">
        <Headline
          v-for="(headline, index) in headlines"
          :key="'headline' + index"
          v-editable="headline"
          v-bind="headline"
        />
      </div>

      <!-- Special upsell section content after checkout -->
      <div
        v-if="upsell && isAfterCheckout"
        class="upsell-offer"
      >
        <div
          v-for="(block, index) in upsellOffer"
          :key="index"
        >
          <TextBlock
            :key="'textblock' + index"
            :text="block.text"
            :headlines="block.headlines"
            :text-align="block.textAlign"
          />
        </div>

        <Timer :end-time="timerEndTime" />
      </div>
      <div
        class="nav"
        :class="{
          show: !hideNav && ((products && products.length > 2) || (items && items.length > 2) || ($slots.products && $slots.products.length > 2)),
        }"
      >
        <span
          ref="prev"
          class="arrow prev"
          @click="slideToPrev"
        >
          <span class="icon">
            <ChevronLeftLarge />
          </span>
        </span>
        <span
          ref="next"
          class="arrow next"
          @click="slideToNext"
        >
          <span class="icon">
            <ChevronRightLarge />
          </span>
        </span>
      </div>
    </div>
    <div
      ref="slider"
      class="products"
      :class="[
        mobilePadding ? 'mp--' + mobilePadding : '',
        desktopPadding ? 'dp--' + desktopPadding : '',
        mobileGridGutter ? 'm-gutter--' + mobileGridGutter : '',
        desktopGridGutter ? `d-gutter--${desktopGridGutter}` : '',
        `m-cols--${mobileColumns}`,
      ]"
      @scroll="scrolling"
    >
      <slot name="products" />
      <template v-if="productCards.length">
        <template v-for="(product, index) in productCards">
          <ProductCardUpsell
            v-if="upsell"
            v-bind="product"
            :key="index + _uid"
            :cross-sell="crossSell"
            :product-id="product.productId || product.product"
            :mobile-display-size="mobileSize"
            :desktop-display-size="desktopSize"
          />
          <ProductCardCentra
            v-else-if="product.component === 'ProductCardCentra'"
            v-bind="product"
            :key="index"
            v-editable="product"
            :position="index"
            :mobile-display-size="mobileSize"
            :desktop-display-size="desktopSize"
            :show-cart-icon="enableAddToCart"
            style="padding-bottom: 16px"
            :client-only="true"
          />
          <ProductCard
            v-else-if="products && products.length"
            :key="categoryUri + '-' + product.product"
            :product="product"
            :position="index + 1"
            :index="index"
            :mobile-display-size="mobileSize"
            :desktop-display-size="desktopSize"
            :show-cart-icon="enableAddToCart"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import ChevronLeftLarge from '@/assets/icons/chevron-left-large.svg'
import ChevronRightLarge from '@/assets/icons/chevron-right-large.svg'

import ProductCard from '~/components/product-card/ProductCard'
import ProductCardCentra from '~/components/product-card-centra/ProductCardCentra'
import ProductCardUpsell from '~/components/product-card-upsell/ProductCardUpsell'
import Timer from '~/components/timer/Timer'

export default {
  name: 'ProductListSwipeable',
  components: {
    ProductCard,
    ProductCardCentra,
    ProductCardUpsell,
    ChevronLeftLarge,
    ChevronRightLarge,
    Timer,
  },
  props: {
    /**
     * This is the category id, from storyblok
     */
    categoryUri: {
      type: String,
      required: false,
      default: null,
    },
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    mobilePadding: {
      type: String,
      required: false,
      default: 'none',
    },
    mobileMargin: {
      type: String,
      required: false,
      default: 'none',
    },
    mobileGridGutter: {
      type: String,
      required: false,
      default: 'small',
    },
    mobileColumns: {
      type: String,
      required: false,
      default: 'peek',
    },
    desktopPadding: {
      type: String,
      required: false,
      default: 'none',
    },
    desktopMargin: {
      type: String,
      required: false,
      default: 'none',
    },
    desktopGridGutter: {
      type: String,
      required: false,
      default: 'small',
    },
    desktopColumns: {
      type: String,
      required: false,
      default: '4',
    },
    pageName: {
      type: String,
      default: null,
    },
    headlines: {
      type: Array,
      required: false,
      default: () => [],
    },
    upsellOffer: {
      type: Array,
      required: false,
      default: () => [],
    },
    enableAddToCart: {
      type: Boolean,
      default: false,
    },
    upsell: {
      type: Boolean,
      default: false,
    },
    hideNav: {
      type: Boolean,
      default: false,
    },
    crossSell: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentScrollPos: 0,
      categoriesLoaded: 1,
      timerOutput: null,
    }
  },
  computed: {
    timerEndTime() {
      if (this.upsell) {
        const savedCrossSellInfo = localStorage.getItem('cross_sell')
        let timeObject
        try {
          timeObject = JSON.parse(savedCrossSellInfo)
          if (timeObject && timeObject.expiresString) {
            return timeObject.expiresString
          }
        } catch (e) {
          console.error(e)
        }
      }
      return ''
    },
    isAfterCheckout() {
      return this.$route.name === 'context-checkout-result'
    },
    products() {
      if (this.items && this.items.length) {
        return this.items
      }
      if (this.categoriesLoaded) {
        return this.$store.getters['centra-product/getProductsByCategoryUriOrId'](this.categoryUri)
      }
      return []
    },

    mobileSize() {
      return `${100 / this.mobileColumns}vw`
    },
    desktopSize() {
      return `${100 / this.desktopColumns}vw`
    },
    productCards() {
      if (this.items && this.items.length) {
        return this.items
      } else if (this.products && this.products.length) {
        return this.products
      } else return []
    },
    hasItemProducts() {
      return !!this.items.find((item) => item.component === 'ProductCardCentra')
    },
    itemProductIds() {
      return this.items.filter((item) => item.component === 'ProductCardCentra').map((item) => item.productId)
    },
  },
  watch: {
    categoryUri(oldVal) {
      if (oldVal !== '') {
        this.lookupCategoryProducts()
      }
    },
  },
  mounted() {
    if (!this.products.length) {
      this.lookupCategoryProducts()
    }
  },
  // serverPrefetch() {
  //   if (!this.products.length) {
  //     return this.lookupCategoryProducts()
  //   }
  // },
  methods: {
    lookupProducts() {
      return this.hasItemProducts ? this.lookupItemProducts() : this.lookupCategoryProducts()
    },
    lookupItemProducts() {
      return this.$store.dispatch('centra-product/lookupProducts', this.itemProductIds)
    },
    lookupCategoryProducts() {
      if (this.categoryUri) {
        return this.$store.dispatch('centra-product/lookupProductsByCategoryUri', this.categoryUri).then(() => {
          // assign a random nonzero value to force reactivity
          // circumvents an issue where the lookup doesnt finish in time when displayed in checkout
          this.categoriesLoaded = Math.random() + 1
        })
      }
    },
    updateValues() {
      /**
       * Set scroll position
       */
      this.currentScrollPos = this.$refs.slider.scrollLeft
    },
    scrolling() {
      this.updateValues()
    },
    slideToPrev() {
      const slideWidth = this.$refs.slider.firstElementChild.clientWidth
      const scrollTo = this.currentScrollPos - slideWidth
      this.$refs.slider.scrollTo({
        left: scrollTo,
        behavior: 'smooth',
      })
    },
    slideToNext() {
      const slideWidth = this.$refs.slider.firstElementChild.clientWidth
      const scrollTo = this.currentScrollPos + slideWidth
      this.$refs.slider.scrollTo({
        left: scrollTo,
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.product-list-swipeable {
  &.search-results__upsell {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: $tablet-landscape) {
      gap: 16px;
    }
  }
  .list-header {
    .nav {
      display: none;
    }

    &.after-checkout {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      .nav {
        position: absolute;
        right: 0;
        bottom: 2rem;
      }
      .upsell-offer {
        padding-bottom: 3rem;
        &::v-deep .headline-text {
          display: flex;
          flex-direction: column;
        }

        &::v-deep .text {
          margin: 0 auto;
          max-width: 50rem;
        }
        &::v-deep .lead {
          display: inline-flex;
        }
      }
    }
  }
  .products {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    @include hide-scrollbars;
    scroll-snap-type: x mandatory;

    .product-card {
      scroll-snap-align: start;
      width: 60%;
      flex-shrink: 0;
      flex-grow: 0;
      padding-bottom: 0.8rem;
      margin-right: 1.6rem;

      ::v-deep {
        .details {
          color: var(--text-color);
        }
      }

      &:last-of-type {
        margin-right: 0 !important;
        scroll-snap-align: end;
      }
    }

    @each $spacing, $value in $spacings {
      &.m-gutter--#{$spacing} {
        .product-card,
        .product-card-upsell {
          margin-right: $value;
        }
      }
    }
  }
}

// Only Mobile
@media screen and (max-width: $tablet-landscape) {
  .product-list-swipeable {
    .products {
      &.m-cols--1 {
        .product-card {
          width: 60%;
        }
      }

      &.m-cols--2 {
        .product-card {
          width: 40%;
        }
      }
    }
  }
}

// Tablet (Portrait)
@media screen and (min-width: $tablet) {
  // ...
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .product-list-swipeable {
    .list-header {
      padding-bottom: spacing('large');
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .headline {
        margin-bottom: 0;
      }

      .nav {
        gap: spacing('small');
        border: 1px solid $grey;

        &.show {
          display: flex;
        }

        .arrow {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 1em;
          width: 4rem;
          height: 4rem;
          color: $black;

          .icon {
            width: 1.4rem;
            height: 1.4rem;
            display: block;
            position: relative;

            svg {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .products {
      .product-card {
        width: 22%;
      }

      @each $spacing, $value in $spacings {
        &.d-gutter--#{$spacing} {
          .product-card,
          .product-card-upsell {
            margin-right: $value;
          }
        }
      }
    }
  }
}

// Laptop
@media screen and (min-width: $laptop) {
  // ...
}
</style>
